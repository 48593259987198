
import { GameState } from '@/utils/types';
import GameBoard from '@/components/GameBoard.vue';
import { defineComponent } from 'vue';
import { io, Socket } from 'socket.io-client';

type ComponentData = {
  games: GameState[];
  socket: Socket;
  gameId: string;
}
export default defineComponent({
  name: 'observe',
  data(): ComponentData {
    return {
      games: [],
      socket: io(process.env.VUE_APP_SOCKET_LOCATION),
      gameId: '',
    };
  },
  mounted(): void {
    this.gameId = this.$route.params.gameId as string;
    this.socket.on('connect', () => {
      this.socket.emit('observer');
    });
    this.socket.on('games', (games: GameState[]) => {
      this.games = games;
      if (this.gameId) {
        this.games = this.games.filter((game) => game.id === this.gameId);
      }
    });
  },
  components: {
    GameBoard,
  },
});
